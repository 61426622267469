<script setup lang="ts">
const props = defineProps<{
  title?: string;
  titleClass?: string;
}>();

const slider = ref<HTMLElement | null>(null);
const atStart = ref(true);
const atEnd = ref(false);
const isScrollButtonsHidden = ref(false);

onMounted(() => {
  checkNavigationBounds();
});

const scroll = async (direction: "prev" | "next") => {
  if (!slider.value) return;

  // Determine the scroll amount based on the slider's width
  let scrollAmount = slider.value.offsetWidth / 2; // Scrolls half the width of the slider container

  if (direction === "prev") {
    slider.value.scrollBy({ left: -scrollAmount, behavior: "smooth" });
  } else {
    slider.value.scrollBy({ left: scrollAmount, behavior: "smooth" });
  }

  // Wait for the scroll action to complete before checking bounds
  setTimeout(checkNavigationBounds, 400); // TODO: dunno whether this is optimal, but we have to wait until the smooth scolling animation has ended before recalculating the bounds
};

const checkNavigationBounds = () => {
  if (!slider.value) return;

  const tolerance = 2;
  const scrollLeft = slider.value.scrollLeft;
  atStart.value = scrollLeft <= tolerance;

  const maxScrollLeft = slider.value.scrollWidth - slider.value.clientWidth;

  atEnd.value = scrollLeft + tolerance >= maxScrollLeft;

  isScrollButtonsHidden.value = atStart.value && atEnd.value;
};

// Recheck navigation bounds when the slider's scroll position changes
watch(() => slider.value?.scrollLeft, checkNavigationBounds);
</script>

<template>
  <div>
    <div
      class="flex flex-row items-center"
      :class="props.title !== undefined ? 'justify-between' : 'justify-end'"
    >
      <div
        v-if="props.title !== undefined"
        class="text-[24px] c-scheppach-primary-700 font-700 mb-2"
        :class="titleClass !== undefined ? titleClass : ''"
      >
        {{ title }}
      </div>
      <div class="flex flex-row gap-5 mb-5"></div>
    </div>

    <div class="flex gap-5 items-center">
      <button
        :disabled="atStart"
        :class="{ 'disabled-btn': atStart, hidden: false }"
        class="p-2 hidden lg:flex justify-center items-center bg-transparent"
        aria-label="slide back"
        @click="scroll('prev')"
      >
        <span
          class="i-sl-arrow-left-1 inline-block text-scheppach-primary-700 text-5"
          name="back-button"
        ></span>
      </button>

      <div
        ref="slider"
        class="flex gap-4 overflow-x-scroll pb-3 sm:pb-0 lg:overflow-x-hidden w-full"
      >
        <slot></slot>
      </div>

      <button
        :disabled="atEnd"
        :class="{ 'disabled-btn': atEnd, hidden: false }"
        class="p-2 hidden lg:flex justify-center items-center bg-transparent"
        aria-label="slide forward"
        @click="scroll('next')"
      >
        <span
          class="i-sl-arrow-right-1 inline-block text-scheppach-primary-700 text-5"
        ></span>
      </button>
    </div>
    <div
      class="flex sm:hidden justify-center items-center gap-2 c-scheppach-primary-700 text-3.5"
    >
      <div class="i-sl-arrow-right rotate-180"></div>
      <div>{{ $t("slideForMore") }}</div>
      <div class="i-sl-arrow-right"></div>
    </div>
  </div>
</template>
